import RegLogo from "../assets/photo/reg.png";
import RwandaAir from "../assets/photo/RwandaAir.png";
import Client1 from "../assets/images/clientImages/client1.png";
import Client2 from "../assets/images/clientImages/client2.png";
import Client3 from "../assets/images/clientImages/client3.png";
import Client4 from "../assets/images/clientImages/client4.png";
import Client5 from "../assets/images/clientImages/client5.png";
import Client6 from "../assets/images/clientImages/client6.png";
import Client7 from "../assets/images/clientImages/client7.png";
import Client8 from "../assets/images/clientImages/client8.png";
import Client9 from "../assets/images/clientImages/client9.jpg";
import Client10 from "../assets/images/clientImages/client10.png";
import Client11 from "../assets/images/clientImages/client11.jpeg";
import Client12 from "../assets/images/clientImages/client12.jpeg";
import Client13 from "../assets/images/clientImages/client13.png";
import Client14 from "../assets/images/clientImages/client14.png";

export const faqLeft = [
  {
    id: 1,
    title:
      "Who are we?",
    content:
      " LCT International is the UK's leading corporate training provider, offering over 150 short courses (3 days - 3 weeks) in the following subject areas: Advanced Management; Leadership; Law; Oil & Gas; Digital; Operations Management; Finance; Human Resources; Public Relations; Sales; Compliance; Sustainability; and Employee Well Being.",
  },
  {
    id: 2,
    title:
      "Which markets do you operate in?",
    content:
      " We serve numerous markets worldwide including Europe, the Middle East, Africa and Asia with clients operating in the following sectors:",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer tailor-made training?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
  {
    id: 3,
    title:
      "Do you offer training overseas?",
    content:
      " ",
  },
];

export const researchDevelopmentComponents = [
  {
    section: "right9child1Title1",
    items: [
      {
        title: "right9child1content1title",
        description:
          "right9child1content1desc",
      },
      {
        title: "right9child1content2title",
        description:
          "right9child1content2desc",
      },
      {
        title: "right9child1content3title",
        description:
          "right9child1content3desc",
      },
      {
        title: "right9child1content4title",
        description:
          "right9child1content4desc",
      },
      {
        title: "right9child1content5title",
        description:
          "right9child1content5desc",
      },
    ],
  },
  {
    section: "right9child2Title2",
    items: [
      {
        title: "right9child2content1title",
        description:
          "right9child2content1desc",
      },
      {
        title: "right9child2content2title",
        description:
          "right9child2content2desc",
      },
      {
        title: "right9child2content3title",
        description:
          "right9child2content3desc",
      },
      {
        title: "right9child2content4title",
        description:
          "right9child2content4desc",
      },
    ],
  },
  {
    section: "right9child3Title3",
    items: [
      {
        title: "right9child3content1title",
        description:
          "right9child3content1desc",
      },
      {
        title: "right9child3content2title",
        description:
          "right9child3content2desc",
      },
      {
        title: "right9child3content3title",
        description:
          "right9child3content3desc",
      },
    ],
  },
];
export const technicalTesting = [
  {
    title: "left1child1Title1",
    description:
      "left1child1content",
  },
  {
    title: "left1chi2d1Title1",
    description:
      "left1chi2d1content",
  },
  {
    title: "left1chi3d1Title1",
    description:
      "left1chi3d1content",
  },
  {
    title: "left1chi4d1Title1",
    description:
      "left1chi4d1content",
  },
  {
    title: "left1chi5d1Title1",
    description:
      "left1chi5d1content",
  },
  {
    title: "left1chi6d1Title1",
    description:
      "left1chi6d1content",
  },
  {
    title: "left1chi7d1Title1",
    description:
      "left1chi7d1content",
  },
  {
    title: "left1chi8d1Title1",
    description:
      "left1chi8d1content",
  },
  {
    title: "left1chi9d1Title1",
    description:
      "left1chi9d1content",
  },
  {
    title: "left1chil0d1Title1",
    description:
      "left1chil0d1content",
  },
  {
    title: "left1child11Title1",
    description:
      "left1child11content",
  },
  {
    title: "left1child12Title1",
    description:
      "left1child12content",
  },
  {
    title: "left1child13Title1",
    description:
      "left1child13content",
  },
];
export const marketResearch = [
  {
    title: "left2child1Title1",
    sections: [
      {
        subtitle: "left2child1content1title",
        description:
          "left2child1content1desc1",
      },
      {
        subtitle: "left2child1content2title",
        points: [
          "left2child1content2desc1",
          "left2child1content2desc2",
        ],
      },
      {
        subtitle: "left2child1content3title",
        points: [
          "left2child1content3desc1",
          "left2child1content3desc2",
          "left2child1content3desc3",
        ],
      },
      {
        subtitle: "left2child1content4title",
        points: [
          "left2child1content4desc1",
          "left2child1content4desc2",
          "left2child1content4desc3",
        ],
      },
    ],
  },
  {
    title: "left2child2Title1",
    sections: [
      {
        subtitle: "left2child1content1title",
        description:
          "left2child2content1desc1",
      },
      {
        subtitle: "left2child1content2title",
        points: [
          "left2child2content2desc1",
          "left2child2content2desc2",
        ],
      },
      {
        subtitle: "left2child1content3title",
        points: [
          "left2child2content3desc1",
          "left2child2content3desc2",
        ],
      },
      {
        subtitle: "left2child1content4title",
        points: [
          "left2child2content4desc1",
          "left2child2content4desc2",
          "left2child2content4desc3",
        ],
      },
    ],
  },
  {
    title: "left2child3content1title",
    description:
      "left2child3content1desc1",
  },
];
export const teamBuilding = [
  {
    title: "left2child1content1title",
    points: [
      "left3child1content1desc1",
      "left3child1content1desc2",
      "left3child1content1desc3",
      "left3child1content1desc4",
    ],
  },
  {
    title: "left3child2content1title",
    points: [
      "left3child2content1desc1",
      "left3child2content1desc2",
      "left3child2content1desc3",
      "left3child2content1desc4",
    ],
  },
  {
    title: "left3child3content1title",
    points: [
      "left3child3content1desc1",
      "left3child3content1desc2",
      "left3child3content1desc3",
    ],
  },
  {
    title: "left3child4content1title",
    points: [
      "left3child4content1desc1",
      "left3child4content1desc2",
      "left3child4content1desc3",
      "left3child4content1desc4",
    ],
  },
  {
    title: "left3child5content1title",
    points: [
      "left3child5content1desc1",
      "left3child5content1desc2",
    ],
  },
];
export const fleetManagement = [
  {
    title: "left4child1content1title",
    points: [
      "left4child1content1desc1",
      "left4child1content1desc2",
      "left4child1content1desc3",
    ],
  },
  {
    title: "left4child2content1title",
    points: [
      "left4child2content1desc1",
      "left4child2content1desc2",
      "left4child2content1desc3",
      "left4child2content1desc4",
    ],
  },
  {
    title: "left4child3content1title",
    points: [
      "left4child3content1desc1",
      "left4child3content1desc2",
    ],
  },
  {
    title: "left4child4content1title",
    points: [
      "left4child4content1desc1",
      "left4child4content1desc2",
    ],
  },
  {
    title: "left4child5content1title",
    points: [
      "left4child5content1desc1",
      "left4child5content1desc2",
      "left4child5content1desc3",
    ],
  },
  {
    title: "left4child6content1title",
    points: [
      "left4child6content1desc1",
      "left4child6content1desc2",
    ],
  },
];
export const customerService = [
  {
    title: "left4child1content1title",
    points: [
      "left5child1content1desc1",
      "left5child1content1desc2",
      "left5child1content1desc3",
    ],
  },
  {
    title: "left4child2content1title",
    points: [
      "left5child2content1desc1",
      "left5child2content1desc2",
      "left5child2content1desc3",
    ],
  },
  {
    title: "left5child3content1title",
    points: [
      "left5child3content1desc1",
      "left5child3content1desc2",
      "left5child3content1desc3",
    ],
  },
  {
    title: "left5child4content1title",
    points: [
      "left5child4content1desc1",
      "left5child4content1desc2",
    ],
  },
  {
    title: "left4child5content1title",
    points: [
      "left5child5content1desc1",
      "left5child5content1desc2",
      "left5child5content1desc3",
    ],
  },
  {
    title: "left4child6content1title",
    points: [
      "left5child6content1desc1",
      "left5child6content1desc2",
    ],
  },
];
export const cultureChange = [
  {
    title: "left4child1content1title",
    points: [
      "left6child1content1desc1",
      "left6child1content1desc2",
      "left6child1content1desc3",
    ],
  },
  {
    title: "left4child2content1title",
    points: [
      "left6child2content1desc1",
      "left6child2content1desc2",
      "left6child2content1desc3",
    ],
  },
  {
    title: "left5child3content1title",
    points: [
      "left6child3content1desc1",
      "left6child3content1desc2",
      "left6child3content1desc3",
    ],
  },
  {
    title: "left4child6content1title",
    points: [
      "left6child4content1desc1",
      "left6child4content1desc2",
      "left6child4content1desc3",
    ],
  },
  {
    title: "left4child5content1title",
    points: [
      "left6child5content1desc1",
      "left6child5content1desc2",
    ],
  },
  {
    title: "left6child6content1title",
    points: [
      "left6child6content1desc1",
      "left6child6content1desc2",
    ],
  },
];
export const leadershipDevelopment = [
  {
    title: "left4child1content1title",
    points: [
      "left7child1content1desc1",
      "left7child1content1desc2",
      "left7child1content1desc3",
    ],
  },
  {
    title: "left4child2content1title",
    points: [
      "left7child2content1desc1",
      "left7child2content1desc1",
      "left7child2content1desc3",
    ],
  },
  {
    title: "left5child3content1title",
    points: [
      "left7child3content1desc1",
      "left7child3content1desc2",
      "left7child3content1desc3",
    ],
  },
  {
    title: "left4child5content1title",
    points: [
      "left7child4content1desc1",
      "left7child4content1desc1",
      "left7child4content1desc3",
    ],
  },
  {
    title: "left4child6content1title",
    points: [
      "left7child5content1desc1",
      "left7child5content1desc2",
    ],
  },
  {
    title: "left7child6content1title",
    points: [
      "left7child6content1desc1",
      "left7child6content1desc2",
    ],
  },
];
export const supervisorySkills = [
  {
    title: "left8child1content1title",
    points: [
      "left8child1content1desc1",
      "left8child1content1desc2",
      "left8child1content1desc3",
    ],
  },
  {
    title: "left8child2content1title",
    points: [
      "left8child2content1desc1",
      "left8child2content1desc2",
      "left8child2content1desc3",
    ],
  },
  {
    title: "left8child3content1title",
    points: [
      "left8child3content1desc1",
      "left8child3content1desc2",
      "left8child3content1desc3",
    ],
  },
  {
    title: "left8child4content1title",
    points: [
      "left8child4content1desc1",
      "left8child4content1desc2",
      "left8child4content1desc3",
    ],
  },
  {
    title: "left8child5content1title",
    points: [
      "left8child5content1desc1",
      "left8child5content1desc2",
      "left8child5content1desc3",
    ],
  },
  {
    title: "left8child6content1title",
    points: [
      "left8child6content1desc1",
      "left8child6content1desc2",
      "left8child6content1desc3",
    ],
  },
  {
    title: "left8child7content1title",
    points: [
      "left8child7content1desc1",
      "left8child7content1desc2",
      "left8child7content1desc3",
    ],
  },
];
export const timeManagement = [
  {
    title: "left4child1content1title",
    points: [
      "left9child1content1desc1",
      "left9child1content1desc2",
      "left9child1content1desc3",
    ],
  },
  {
    title: "left5child3content1title",
    points: [
      "left9child2content1desc1",
      "left9child2content1desc2",
      "left9child2content1desc3",
      "left9child2content1desc4",
      "left9child2content1desc5",
      "left9child2content1desc6",
    ],
  },
  {
    title: "left9child3content1title",
    points: [
      "left9child3content1desc1",
      "left9child3content1desc2",
      "left9child3content1desc3",
    ],
  },
  {
    title: "left4child5content1title",
    points: [
      "left9child4content1desc1",
      "left9child4content1desc2",
      "left9child4content1desc3",
    ],
  },
  {
    title: "left4child6content1title",
    points: [
      "left9child5content1desc1",
      "left9child5content1desc2",
      "left9child5content1desc3",
    ],
  },
  {
    title: "left9child6content1title",
    points: [
      "left9child6content1desc1",
      "left9child6content1desc2",
    ],
  },
];

export const consultancyDetails = {
  components: [
    {
      title: "right1child1Title1",
      description:
        "right1child1content",
    },
    {
      title: "right1child2Title1",
      description:
        "right1child2content",
    },
    {
      title: "right1child3Title1",
      description:
        "right1child3content",
    },
    {
      title: "right1child4Title1",
      description:
        "right1child4content",
    },
    {
      title: "right1child5Title1",
      description:
        "right1child5content",
    },
  ],
};
export const financialTrainingComponents = [
  {
    title: "right2child1Title1",
    description:
      "right2child1content",
  },
  {
    title: "right2child2Title1",
    description:
      "right2child2content",
  },
  {
    title: "right2child3Title1",
    description:
      "right2child3content",
  },
  {
    title: "right2child4Title1",
    description:
      "right2child4content",
  },
  {
    title: "right2child5Title1",
    description:
      "right2child5content",
  },
  {
    title: "right2child6Title1",
    description:
      "right2child6content",
  },
  {
    title: "right2child7Title1",
    description:
      "right2child7content",
  },
];
export const strategicPlanningSteps = [
  {
    title: "right3child1Title1",
    description:
      "right3child1content",
  },
  {
    title: "right3child2Title1",
    description:
      "right3child2content",
  },
  {
    title: "right3child3Title1",
    description:
      "right3child3content",
  },
  {
    title: "right3child4Title1",
    description:
      "right3child4content",
  },
  {
    title: "right3child5Title1",
    description:
      "right3child5content",
  },
  {
    title: "right3child6Title1",
    description:
      "right3child6content",
  },
  {
    title: "right3child7Title1",
    description:
      "right3child7content",
  },
];
export const capacityBuildingComponents = [
  {
    title: "right4child1Title1",
    description:
      "right4child1content",
  },
  {
    title: "right4child2Title1",
    description:
      "right4child2content",
  },
  {
    title: "right4child3Title1",
    description:
      "right4child3content",
  },
  {
    title: "right4child4Title1",
    description:
      "right4child4content",
  },
  {
    title: "right4child5Title1",
    description:
      "right4child5content",
  },
  {
    title: "right4child6Title1",
    description:
      "right4child6content",
  },
  {
    title: "right4child7Title1",
    description:
      "right4child7content",
  },
];
export const keyAspects = [
  {
    title: "right5child1Title1",
    items: [
      {
        subTitle: "right5child1content1title",
        description:
          "right5child1content1desc",
      },
      {
        subTitle: "right5child1content2title",
        description:
          "right5child1content2desc",
      },
      {
        subTitle: "right5child1content3title",
        description:
          "right5child1content3desc",
      },
      {
        subTitle: "right5child1content4title",
        description:
          "right5child1content4desc",
      },
      {
        subTitle: "right5child1content5title",
        description:
          "right5child1content5desc",
      },
      {
        subTitle: "right5child1content6title",
        description:
          "right5child1content6desc",
      },
      {
        subTitle: "right5child1content7title",
        description:
          "right5child1content7desc",
      },
    ],
  },
  {
    title: "right5child1Title2",
    items: [
      {
        subTitle: "right5child2content1title",
        description:
          "right5child2content1desc",
      },
      {
        subTitle: "right5child2content2title",
        description:
          "right5child2content2desc",
      },
      {
        subTitle: "right5child2content3title",
        description:
          "right5child2content3desc",
      },
      {
        subTitle: "right5child2content4title",
        description:
          "right5child2content4desc",
      },
      {
        subTitle: "right5child2content5title",
        description:
          "right5child2content5desc",
      },
    ],
  },
];
export const EIAComponents = [
  {
    title: "right6child1Title1",
    description:
      "right6child1content",
  },
  {
    title: "right6child2Title1",
    description:
      "right6child2content",
  },
  {
    title: "right6child3Title1",
    description:
      "right6child3content",
  },
  {
    title: "right6child4Title1",
    description:
      "right6child4content",
  },
  {
    title: "right6child5Title1",
    description:
      "right6child5content",
  },
  {
    title: "right6child6Title1",
    description:
      "right6child6content",
  },
  {
    title: "right6child7Title1",
    description:
      "right6child7content",
  },
  {
    title: "right6child8Title1",
    description:
      "right6child8content",
  },
];
export const baselineStudyComponents = [
  {
    title: "right7child1Title1",
    description:
      "right7child1content",
  },
  {
    title: "right7child2Title1",
    description:
      "right7child2content",
  },
  {
    title: "right7child3Title1",
    description:
      "right7child3content",
  },
  {
    title: "right7child4Title1",
    description:
      "right7child4content",
  },
  {
    title: "right7child5Title1",
    description:
      "right7child5content",
  },
  {
    title: "right7child6Title1",
    description:
      "right7child6content",
  },
  {
    title: "right7child7Title1",
    description:
      "right7child7content",
  },
];
export const monitoringEvaluationComponents = [
  {
    section: "right8child1Title1",
    items: [
      {
        title: "right8child1content1title",
        description:
          "right8child1content1desc",
      },
      {
        title: "right8child1content2title",
        description:
          "right8child1content2desc",
      },
      {
        title: "right8child1content3title",
        description:
          "right8child1content3desc",
      },
      {
        title: "right8child1content4title",
        description:
          "right8child1content4desc",
      },
      {
        title: "right8child1content5title",
        description:
          "right8child1content5desc",
      },
    ],
  },
  {
    section: "right8child2Title2",
    items: [
      {
        title: "right8child2content1title",
        description:
          "right8child2content1desc",
      },
      {
        title: "right8child2content2title",
        description:
          "right8child2content2desc",
      },
      {
        title: "right8child2content3title",
        description:
          "right8child2content3desc",
      },
      {
        title: "right8child2content4title",
        description:
          "right8child2content4desc",
      },
      {
        title: "right8child2content5title",
        description:
          "right8child2content5desc",
      },
    ],
  },
  {
    section: "right8child3Title3",
    items: [
      {
        title: "right8child3content1title",
        description:
          "right8child3content1desc",
      },
      {
        title: "right8child3content2title",
        description:
          "right8child3content2desc",
      },
      {
        title: "right8child3content3title",
        description:
          "right8child3content3desc",
      },
    ],
  },
];

export const feasibilityStudy = [
  {
    title: "right10child1content1title",
    points: [
      "right10child1content1desc1",
      "right10child1content1desc2",
      "right10child1content1desc3",
    ],
  },
  {
    title: "right10child1content2title",
    points: [
      "right10child1content2desc1",
      "right10child1content2desc2",
      "right10child1content2desc3",
      "right10child1content2desc4",
      "right10child1content2desc5",
      "right10child1content2desc6",
    ],
  },
  {
    title: "right10child1content3title",
    points: [
      "right10child1content3desc1",
      "right10child1content3desc2",
      "right10child1content3desc3",
      "right10child1content3desc4",
      "right10child1content3desc5",
    ],
  },
  {
    title: "right10child1content4title",
    points: [
      "right10child1content4desc1",
      "right10child1content4desc2",
      "right10child1content4desc3",
      "right10child1content4desc4",
    ],
  },
  {
    title: "right10child1content5title",
    points: [
      "right10child1content5desc1",
      "right10child1content5desc2",
      "right10child1content5desc3",
      "right10child1content5desc4",
    ],
  },
];

export const processSteps = [
  {
    title: "right11child1content1title",
    details: [
      "right11child1content1desc1",
      "right11child1content1desc2",
    ],
  },
  {
    title: "right11child2content1title",
    details: [
      "right11child2content1desc1",
      "right11child2content1desc2",
    ],
  },
  {
    title: "right11child3content1title",
    details: [
      "right11child3content1desc1",
      "right11child3content1desc2",
    ],
  },
  {
    title: "right11child4content1title",
    details: [
      "right11child4content1desc1",
      "right11child4content1desc2",
      "right11child4content1desc3",
    ],
  },
  {
    title: "right11child5content1title",
    details: [
      "right11child5content1desc1",
      "right11child5content1desc2",
      "right11child5content1desc3",
    ],
  },
  {
    title: "right11child6content1title",
    details: [
      "right11child6content1desc1",
    ],
  },
  {
    title: "right11child7content1title",
    details: [
      "right11child7content1desc1",
      "right11child7content1desc2",
    ],
  },
  {
    title: "right11child8content1title",
    details: [
      "right11child8content1desc1",
      "right11child8content1desc2",
    ],
  },
  {
    title: "right11child9content1title",
    details: [
      "right11child9content1desc1",
      "right11child9content1desc2",
      "right11child9content1desc3",
    ],
  },
];

export const companyLogos = [
  RegLogo,
  RwandaAir,
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
  Client10,
  Client11,
  Client12,
  Client13,
  Client14,
];

export const architecturalEngineering = [
  {
    title: "right12child1title",
    details: [
      "right12child1desc1",
      "right12child1desc2",
      "right12child1desc3",
      "right12child1desc4",
      "right12child1desc5",
      "right12child1desc6",
    ],
  },
  {
    title: "right12child2title",
    details: [
      {
        subTitle: "right12child2content1title",
        points: [
          "right12child2content1desc1",
          "right12child2content1desc2",
          "right12child2content1desc3",
        ],
      },
      {
        subTitle: "right12child2content2title",
        points: [
          "right12child2content2desc1",
          "right12child2content2desc2",
        ],
      },
      {
        subTitle: "right12child2content3title",
        points: [
          "right12child2content3desc1",
          "right12child2content3desc2",
          "right12child2content3desc3",
        ],
      },
    ],
  },
  {
    title: "right12child3title",
    details: [
      "right12child3desc1",
      "right12child3desc2",
      "right12child3desc3",
      "right12child3desc4",
    ],
  },
  {
    title: "right12child4title",
    details: [
      "right12child4desc1",
      "right12child4desc2",
      "right12child4desc3",
    ],
  },
  {
    title: "right12child5title",
    details: [
      "right12child5desc1",
      "right12child5desc2",
      "right12child5desc3",
    ],
  },
  {
    title: "right12child6title",
    details: [
      "right12child6desc1",
      "right12child6desc2",
      "right12child6desc3",
    ],
  },
];

export const accountingFinancial = [
  {
    title: "right13child1title",
    intro:
      "right13child1intro",
    details: [
      "right13child1desc1",
      "right13child1desc2",
      "right13child1desc3",
      "right13child1desc4",
    ],
  },
  {
    title: "right13child2title",
    intro:
      "right13child2intro",
    details: [
      "right13child2desc1",
      "right13child2desc2",
      "right13child2desc3",
      "right13child2desc4",
    ],
  },
  {
    title: "right13child3title",
    intro:
      "right13child3intro",
    details: [
      {
        subTitle: "right13child3contentTitle",
        points: [
          "right13child3contentdesc1",
          "right13child3contentdesc2",
          "right13child3contentdesc3",
          "right13child3contentdesc4",
        ],
      },
    ],
  },
  {
    title: "right13child4title",
    intro:
      "right13child4intro",
    details: [
      "right13child4desc1",
      "right13child4desc2",
      "right13child4desc3",
      "right13child4desc4",
    ],
  },
];

export const category = [
  { id: 2, name: "Finance" },
  { id: 3, name: "Gas and Oil" },
  { id: 4, name: "Human Resources" },
  { id: 6, name: "Management" },
  { id: 7, name: "Operations Management" },
  { id: 8, name: "Public Relations" },
  { id: 10, name: "Secretarial Practices" },
  { id: 9, name: "Sales and Marketing" },
  { id: 5, name: "Law" },
];