import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import {
  architecturalEngineering,
  baselineStudyComponents,
  capacityBuildingComponents,
  consultancyDetails,
  EIAComponents,
  feasibilityStudy,
  financialTrainingComponents,
  keyAspects,
  monitoringEvaluationComponents,
  processSteps,
  researchDevelopmentComponents,
  strategicPlanningSteps,
} from "../../assets/data";
import { useTranslation } from "react-i18next";

const ConsultancyCardRight: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | string[]>(["1"]);
  const { t } = useTranslation();
  const manualTypes = [
    "HR Manual",
    "Procedure & Operations Manual",
    "Financial Manual",
    "Procurement Manual",
    "ICT Manual",
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_10")}
          </h1>
          {activeKey?.at(0) !== "1" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),

      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right1childH")}
          </h1>
          {consultancyDetails.components.map((component, index) => (
            <div key={index} className="component-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_9")}
          </h1>
          {activeKey?.at(0) !== "2" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right2childH")}
          </h1>
          {financialTrainingComponents.map((component, index) => (
            <div key={index} className="component-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_1")}
          </h1>
          {activeKey?.at(0) !== "3" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right3childH")}
          </h1>
          {strategicPlanningSteps.map((step, index) => (
            <div key={index} className="step-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(step.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(step.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_2")}
          </h1>
          {activeKey?.at(0) !== "4" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right4childH")}
          </h1>
          {capacityBuildingComponents.map((component, index) => (
            <div key={index} className="component-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "5",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_6")}
          </h1>
          {activeKey?.at(0) !== "5" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {keyAspects.map((aspect, index) => (
            <div key={index} className="aspect-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              {aspect.items.map((item, subIndex) => (
                <div key={subIndex} className="item-details">
                  <h3 className="font-medium text-base font-serif mb-2">
                    {t(item.subTitle)}
                  </h3>
                  <p className="text-base font-normal py-1 font-serif">
                    {t(item.description)}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </>
      ),
    },
    {
      key: "6",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_7")}
          </h1>
          {activeKey?.at(0) !== "6" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right4childH")}
          </h1>
          {EIAComponents.map((component, index) => (
            <div key={index} className="component-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "7",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_5")}
          </h1>
          {activeKey?.at(0) !== "7" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right4childH")}
          </h1>
          {baselineStudyComponents.map((component, index) => (
            <div key={index} className="component-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "8",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_3")}
          </h1>
          {activeKey?.at(0) !== "8" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right4childH")}
          </h1>
          {monitoringEvaluationComponents.map((section, index) => (
            <div key={index} className="section-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(section.section)}
              </h2>
              {section.items.map((item, idx) => (
                <div key={idx} className="item">
                  <h3 className="font-semibold text-base font-sans mt-1.5">
                    {t(item.title)}
                  </h3>
                  <p className="text-base font-normal py-1 font-serif">
                    {t(item.description)}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </>
      ),
    },
    {
      key: "9",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_8")}
          </h1>
          {activeKey?.at(0) !== "9" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {researchDevelopmentComponents.map((section, index) => (
            <div key={index} className="section-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(section.section)}
              </h2>
              {section.items.map((item, idx) => (
                <div key={idx} className="item">
                  <h3 className="font-semibold text-base font-sans mt-1.5">
                    {t(item.title)}
                  </h3>
                  <p className="text-base font-normal py-1 font-serif">
                    {t(item.description)}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </>
      ),
    },
    {
      key: "10",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_11")}
          </h1>
          {activeKey?.at(0) !== "10" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right10childH")}
          </h1>
          {feasibilityStudy.map((section, index) => (
            <div key={index} className="section-item">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(section.title)}
              </h2>
              <ul className="text-base font-normal font-sans py-1">
                {section.points.map((point, idx) => (
                  <li key={idx}>{t(point)}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "11",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat9")}
          </h1>
          {activeKey?.at(0) !== "11" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right11child1contentTitle1")}
          </h1>
          <ul className=" list-inside mb-4">
            {processSteps.map((step, index) => (
              <li key={index} className="mb-2">
                <h2 className="font-medium text-lg font-sans my-2">
                  {t(step.title)}
                </h2>
                <ul className="text-base font-normal font-sans py-1">
                  {step.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{t(detail)}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <h2 className="font-medium text-lg font-sans my-2">
            {t("right11child1contentTitle2")}
          </h2>
          <ul className="list-disc ml-6 text-base font-normal font-serif">
            {manualTypes.map((point, index) => (
              <li key={index} className="mb-2">
                {t(point)}
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      key: "12",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("right12title")}
          </h1>
          {activeKey?.at(0) !== "12" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right12child6other1")}
          </h1>

          {architecturalEngineering.map((service, index) => (
            <div key={index} className="service-section">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(service.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {service.details.map((detail, detailIndex) => {
                  if (typeof detail === "string") {
                    return (
                      <li key={detailIndex} className="mb-2">
                        {t(detail)}
                      </li>
                    );
                  } else {
                    return (
                      <>
                        <h3 className="font-semibold text-base font-sans mt-1.5 mb-2">
                          {t(detail.subTitle)}
                        </h3>
                        {detail.points.map((point, pointIndex) => (
                          <li key={pointIndex} className="mb-2">
                            {t(point)}
                          </li>
                        ))}
                      </>
                    );
                  }
                })}
              </ul>
            </div>
          ))}

          <h2 className="font-medium text-lg font-sans my-2">
            {t("right12child6other2")}
          </h2>
          <p className="text-base font-normal py-1 font-serif">
            {t("right12child6other3")}
          </p>
        </>
      ),
    },
  ];
  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  return (
    <Collapse
      items={items}
      defaultActiveKey={["1"]}
      onChange={onChange}
      accordion
    />
  );
};

export default ConsultancyCardRight;
