import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import {
  accountingFinancial,
  cultureChange,
  customerService,
  fleetManagement,
  leadershipDevelopment,
  marketResearch,
  supervisorySkills,
  teamBuilding,
  technicalTesting,
  timeManagement,
} from "../../assets/data";
import { useTranslation } from "react-i18next";

const ConsultancyCardLeft: React.FC = () => {
  const { t } = useTranslation();
  const groomingContent = {
    definition: "left10child1content1desc1",
    importance: [
      "left10child1content1desc2",
      "left10child1content1desc3",
      "left10child1content1desc4",
    ],
    keyAspects: [
      "left10child1content1desc5",
      "left10child1content1desc6",
      "left10child1content1desc7",
    ],
  };

  const communicationContent = {
    definition: "left10child2content1desc1",
    importance: [
      "left10child2content1desc2",
      "left10child2content1desc3",
      "left10child2content1desc4",
    ],
    keyComponents: [
      "left10child2content1desc5",
      "left10child2content1desc6",
      "left10child2content1desc7",
      "left10child2content1desc8",
    ],
    techniques: [
      "left10child2content1desc9",
      "left10child2content1desc10",
      "left10child2content1desc11",
    ],
  };

  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_10")}
          </h1>
          {activeKey?.at(0) !== "1" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right4childH")}
          </h1>
          {technicalTesting.map((component, index) => (
            <div key={index} className="my-2">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(component.description)}
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat1_11")}
          </h1>
          {activeKey?.at(0) !== "2" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("left2childH")}
          </h1>
          {marketResearch.map((component, index) => (
            <div key={index} className="mb-3">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(component.title)}
              </h2>
              {component.sections ? (
                component.sections.map((section, idx) => (
                  <div key={idx} className="my-2">
                    <h3 className="font-semibold text-base font-sans mt-1.5">
                      {t(section.subtitle)}
                    </h3>
                    {section.description && (
                      <p className="text-base font-normal py-1 font-serif">
                        {t(section.description)}
                      </p>
                    )}
                    {section.points && (
                      <ul className="list-disc ml-6 text-base font-normal font-serif">
                        {section.points.map((point, i) => (
                          <li key={i}>{t(point)}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-base font-normal py-1 font-serif">
                  {t(component.description)}
                </p>
              )}
            </div>
          ))}
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_1")}
          </h1>
          {activeKey?.at(0) !== "3" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {teamBuilding.map((aspect, index) => (
            <div key={index} className="mb-3">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i}>{t(point)}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_2")}
          </h1>
          {activeKey?.at(0) !== "4" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {fleetManagement.map((aspect, index) => (
            <div key={index} className="mb-3">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i}>{t(point)}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "5",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_3")}
          </h1>
          {activeKey?.at(0) !== "5" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {customerService.map((aspect, index) => (
            <div key={index} className="mb-3">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i}>{t(point)}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "6",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_4")}
          </h1>
          {activeKey?.at(0) !== "6" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {cultureChange.map((aspect, index) => (
            <div key={index} className="mb-8">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i} className="mb-2">
                    {t(point)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "7",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_5")}
          </h1>
          {activeKey?.at(0) !== "7" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {leadershipDevelopment.map((aspect, index) => (
            <div key={index} className="mb-8">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i} className="mb-2">
                    {t(point)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "8",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_6")}
          </h1>
          {activeKey?.at(0) !== "8" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {supervisorySkills.map((aspect, index) => (
            <div key={index} className="mb-8">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i} className="mb-2">
                    {t(point)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "9",
      label: (
        <>
          <h1 className="font-medium text-lg font-serif">
            {t("Ourservicescat2_7")}
          </h1>
          {activeKey?.at(0) !== "9" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right5childH")}
          </h1>
          {timeManagement.map((aspect, index) => (
            <div key={index} className="mb-8">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(aspect.title)}
              </h2>
              <ul className="list-disc ml-6 text-base font-normal font-serif">
                {aspect.points.map((point, i) => (
                  <li key={i} className="mb-2">
                    {t(point)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    },
    {
      key: "10",
      label: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("Ourservicescat2_8")}
          </h1>
          {activeKey?.at(0) !== "10" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <section className="mb-8">
            <h2 className="font-medium text-lg font-sans my-2">
              {t("left10childH1")}
            </h2>
            <p className="text-base font-normal py-1 font-serif">
              {t(groomingContent.definition)}
            </p>
            <h3 className="font-semibold text-base font-sans my-1.5">
              {t("right9child3Title3")}
            </h3>
            <ul className="list-disc ml-6 my-2 text-base font-normal font-serif">
              {groomingContent.importance.map((point, index) => (
                <li key={index} className="mb-2">
                  {t(point)}
                </li>
              ))}
            </ul>
            <h3 className="font-semibold text-base font-sans my-1.5">
              {t("left10childH2")}
            </h3>
            <ul className="list-disc ml-6 text-base font-normal font-serif">
              {groomingContent.keyAspects.map((point, index) => (
                <li key={index} className="mb-2">
                  {t(point)}
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h2 className="font-medium text-lg font-sans my-2">
              {t("left8child1content1title")}
            </h2>
            <p className="text-base font-normal py-1 font-serif">
              {t(communicationContent.definition)}
            </p>
            <h3 className="font-semibold text-base font-sans my-1.5">
              {t("right9child3Title3")}
            </h3>
            <ul className="list-disc ml-6 my-2 text-base font-normal font-serif">
              {communicationContent.importance.map((point, index) => (
                <li key={index} className="mb-2">
                  {t(point)}
                </li>
              ))}
            </ul>
            <h3 className="font-semibold text-base font-sans my-1.5">
              {t("right10child1content2title")}
            </h3>
            <ul className="list-disc ml-6 my-2 text-base font-normal font-serif">
              {communicationContent.keyComponents.map((point, index) => (
                <li key={index} className="mb-2">
                  {t(point)}
                </li>
              ))}
            </ul>
            <h3 className="font-semibold text-base font-sans my-1.5">
              {t("left10childH3")}
            </h3>
            <ul className="list-disc ml-6 text-base font-normal font-serif">
              {communicationContent.techniques.map((point, index) => (
                <li key={index} className="mb-2">
                  {t(point)}
                </li>
              ))}
            </ul>
          </section>
        </>
      ),
    },
    {
      key: "11",
      label: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right13title")}
          </h1>
          {activeKey?.at(0) !== "11" && (
            <span className="cursor-pointer bg-blue-50 text-blue-500 mt-1.5 py-0.5 w-32 text-center rounded-md">
              {t("ReadMore")}
            </span>
          )}
        </>
      ),
      children: (
        <>
          <h1 className="font-medium text-lg font-sans mb-3">
            {t("right13child6other2")}
          </h1>
          {accountingFinancial.map((service, index) => (
            <div key={index} className="mb-6">
              <h2 className="font-medium text-lg font-sans my-2">
                {t(service.title)}
              </h2>
              <p className="text-base font-normal py-1 font-serif">
                {t(service.intro)}
              </p>
              {service.details.map((detail, detailIndex) =>
                typeof detail === "string" ? (
                  <p
                    key={detailIndex}
                    className="text-base font-normal py-1 font-serif"
                  >
                    {t(detail)}
                  </p>
                ) : (
                  <div key={detailIndex} className="mb-2">
                    <h3 className="font-semibold text-base font-sans mt-1.5 mb-2">
                      {t(detail.subTitle)}
                    </h3>
                    <ul className="list-disc ml-6 text-base font-normal font-serif">
                      {detail.points.map((point, pointIndex) => (
                        <li key={pointIndex} className="mb-2">
                          {t(point)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  return <Collapse items={items} onChange={onChange} accordion />;
};

export default ConsultancyCardLeft;
